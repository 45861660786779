import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_1/sub_2/slide1';
// import BackgroundAnimation from '../../../../components/desktop/backgroundAnimation/backgroundAnimation.jsx';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "EN" }, title: { eq: "slide-1-2-1-1" } }
      ) {
        body
      }
      file(relativePath: { eq: "chapter_1/sub_2/1.png" }) {
        childImageSharp {
          fixed(width: 782) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);
  return <Template query={query} title="Part II" />;
};

export default Slide;
